body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  overflow-x: hidden;
}

code {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a5c9ca;
  height: 30%;
  outline: 1px solid #82999a;
  outline-offset: 0px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #82999a;
  outline: 1px solid #a5c9ca;
}
